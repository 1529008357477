import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import { IBoostActivateData, IBoostsData } from "@schemas/boosts";

const url = API_URLS.getBoosts;

export class BoostAPI {
    static async getBoosts(): AxiosPromise<IBoostsData[]> {
        return Request.get(`${url}`).then((response) => {
            if (!response.data.success) throw new Error(response.data.message);
            return response.data;
        });
    }

    static async activateBoost(
        boostId: number
    ): AxiosPromise<IBoostActivateData> {
        return Request.post(`${url}${boostId}/`).then((response) => {
            if (!response.data.success) throw new Error(response.data.message);
            return response.data;
        });
    }
}
