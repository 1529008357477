import { memo, useEffect } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import MainLayout from "@organisms/layouts/MainLayout";

import { lootboxesSelector } from "@redux/lootboxes/lootboxes.selectors";
import { getLootBoxData } from "@redux/lootboxes/lootboxes.thunk";

import AvailablePerks from "./components/AvailablePerks";
import ShelfInfoBlocks from "./components/ShelfInfoBlocks";

const ShelfScreen = () => {
    const dispatch = useAppDispatch();

    const { lootBoxes } = useAppSelector(lootboxesSelector);

    useEffect(() => {
        if (!lootBoxes) {
            dispatch(getLootBoxData());
        }
    }, [dispatch, lootBoxes]);

    return (
        <MainLayout
            additionalScrollHeight={20}
            subtitle="Get extra game passes and perks from the lootboxes for your rewardeed points"
            title="Shelf"
        >
            <ShelfInfoBlocks />

            <AvailablePerks />
        </MainLayout>
    );
};

export default memo(ShelfScreen);
