import { FC } from "react";

import SunSpinner from "@atoms/SunSpinner";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import style from "../PlayScreen.module.scss";
import { IPassTimeCounter } from "../types";

const PassTimeCounter: FC<IPassTimeCounter> = ({ targetDate, isLoading }) => {
    return (
        <WindowBlock className={style.passTimeCounter}>
            <Typography
                className={style.regularOpacity}
                variant="captionRegular"
            >
                Get new game pass package every 8 hours
            </Typography>
            <Typography variant="h1Bold">
                {!isLoading ? (
                    targetDate
                ) : (
                    // <CountdownTimer targetDate={targetDate} />
                    <SunSpinner height="30px" width="30px" />
                )}
            </Typography>
        </WindowBlock>
    );
};

export default PassTimeCounter;
