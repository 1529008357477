import { useLayoutEffect, useRef, useState } from "react";

import Header from "@organisms/Header";
import Navbar from "@organisms/Navbar";

import styles from "./MainLayout.module.scss";
import { IMainLayout } from "./MainLayout.types";

const MainLayout: FCC<IMainLayout> = ({
    children,
    subtitle,
    title,
    hasNavbar = true,
    navigateURL,
    additionalScrollHeight = 0,
    withGoBack,
}) => {
    const [navHeight, setNavHeight] = useState(0);

    const navRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (navRef.current) {
            const { height } = navRef.current?.getBoundingClientRect() ?? {};
            setNavHeight(height);
        }
    }, []);

    return (
        <main className={styles.wrapper}>
            <Header
                navigateURL={navigateURL}
                subtitle={subtitle}
                title={title}
                withGoBack={withGoBack}
            />
            <div className={styles.content}>{children}</div>
            {hasNavbar ? <Navbar ref={navRef} /> : <> </>}
            <div
                style={{
                    minHeight: (navHeight ?? 0) + additionalScrollHeight,
                    width: "100%",
                }}
            />
        </main>
    );
};

export default MainLayout;
