import { memo, useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { useManageModal } from "@hooks/useManageModal";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock";
import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";

import InfoCard from "@molecules/InfoCard";
import InfoCardsList from "@molecules/InfoCardsList";

import ShelfExtraGameModal from "@organisms/Modals/ShelfExtraGameModal";
import ShelfPerksModal from "@organisms/Modals/ShelfPerksModal";

import { ArrowRightSVG } from "@svg/ArrowRigthSVG";
import { MoneySVG } from "@svg/MoneySVG";
import { PresentSVG } from "@svg/PresentSVG";
import { TicketSVG } from "@svg/TicketSVG";

import { boostSelector } from "@redux/boost/boost.selectors";
import { IBoostState } from "@redux/boost/boost.slice";
import { activateBoost, getBoostList } from "@redux/boost/boost.thunk";
import { lootboxesSelector } from "@redux/lootboxes/lootboxes.selectors";
import { ILootBoxesState } from "@redux/lootboxes/lootboxes.slice";
import { userSelector } from "@redux/users/users.selectors";
import { userSlice } from "@redux/users/users.slice";

import styles from "./ShelfInfoBlocks.module.scss";

const ShelfInfoBlocks = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [selectedLootBox, setSelectedLootBox] =
        useState<null | ILootBoxesState>(null);
    const [activeBoost, setActiveBoost] = useState<IBoostState | null>(null);

    const {
        onCloseHandler: onPerksCloseHandler,
        onOpenHandler: onPerksOpenHandler,
        open: openPerks,
    } = useManageModal();
    const {
        onCloseHandler: onExtraGameCloseHandler,
        // @ts-ignore
        onOpenHandler: onExtraGameOpenHandler,
        open: openExtraGame,
    } = useManageModal();

    const handleGetPerksPage = () => navigate(routes.perks);

    const {
        isLoadingActivateBoost,
        boosts,
        isLoading: isBoostLoading,
    } = useAppSelector(boostSelector);
    const {
        lootBoxes,
        lootBoxId,
        isLoading: isLootboxesLoading,
    } = useAppSelector(lootboxesSelector);
    const { userData } = useAppSelector(userSelector);
    const { points = 0 } = userData ?? {};
    const numberPoints = +points;

    const handleActivateBoost = useCallback(
        async (boost: IBoostState) => {
            const {
                payload: { user },
            } = (await dispatch(activateBoost(boost.id))) as any;

            if (!user) {
                return;
            }

            dispatch(userSlice.actions.updateUserData(user));
        },
        [dispatch]
    );

    const handleOpenLootBox = async (lootBox: ILootBoxesState) => {
        setSelectedLootBox(lootBox);
        onPerksOpenHandler();
    };

    const handleSetActiveBoost = (boost: IBoostState) => {
        setActiveBoost(boost);
        onExtraGameOpenHandler();
    };

    const handleCloseExtraGamesModal = useCallback(() => {
        onExtraGameCloseHandler();
        setActiveBoost(null);
    }, [onExtraGameCloseHandler]);

    const handleBoostExtraGame = useCallback(() => {
        if (activeBoost) {
            handleActivateBoost(activeBoost);
            handleCloseExtraGamesModal();
        }
    }, [activeBoost, handleActivateBoost, handleCloseExtraGamesModal]);

    useEffect(() => {
        if (!boosts) {
            dispatch(getBoostList());
        }
    }, [boosts, dispatch]);

    return (
        <div className={styles.wrapper}>
            <ShelfPerksModal
                btnPointsAmount={selectedLootBox?.price}
                lootBoxId={lootBoxId!}
                open={openPerks}
                presentsAmount={selectedLootBox?.count}
                selectedLootBox={selectedLootBox}
                onClose={onPerksCloseHandler}
            />

            <ShelfExtraGameModal
                buttonPoints={activeBoost?.spendAmount ?? 0}
                description={activeBoost?.description ?? ""}
                open={openExtraGame}
                title={activeBoost?.title ?? ""}
                onClick={handleBoostExtraGame}
                onClose={handleCloseExtraGamesModal}
            />

            {isBoostLoading ? (
                <StandardSunSpinner />
            ) : (
                <InfoCardsList className={styles.boosts} title="Boosts">
                    {boosts?.map((boost, i) => {
                        return (
                            <InfoCard
                                key={i}
                                buttonProps={{
                                    text: "Pick up",
                                    status: isLoadingActivateBoost
                                        ? "loading"
                                        : undefined,
                                    onClick: () => handleSetActiveBoost(boost),
                                    disabled: numberPoints < boost.spendAmount,
                                }}
                                icon={
                                    <IconBlock
                                        fullSize
                                        borderRadius="big"
                                        mode="gray"
                                    >
                                        <TicketSVG height={20} width={20} />
                                    </IconBlock>
                                }
                                text={boost.description}
                                title={boost.title}
                            />
                        );
                    })}
                </InfoCardsList>
            )}

            {isLootboxesLoading ? (
                <StandardSunSpinner />
            ) : (
                <InfoCardsList title="Lootboxes">
                    <InfoCard
                        button={
                            <Button autoSize variant="transparent">
                                <ArrowRightSVG />
                            </Button>
                        }
                        contentClassName={styles["content-center"]}
                        icon={
                            <IconBlock
                                fullSize
                                borderRadius="big"
                                mode="gradient"
                            >
                                <MoneySVG height={20} width={20} />
                            </IconBlock>
                        }
                        title="Your perks list"
                        onClick={handleGetPerksPage}
                    />

                    {lootBoxes?.map((lootBox, i) => {
                        return (
                            <InfoCard
                                key={i}
                                buttonProps={{
                                    text: "Pick up",
                                    onClick: () => handleOpenLootBox(lootBox),
                                    disabled: numberPoints < lootBox.price,
                                }}
                                icon={
                                    <IconBlock
                                        fullSize
                                        borderRadius="big"
                                        mode="gray"
                                    >
                                        <PresentSVG height={20} width={20} />
                                    </IconBlock>
                                }
                                iconCounter={lootBox.count}
                                text={`For ${lootBox.price} points`}
                                title={`Open ${lootBox.count} lootbox`}
                            />
                        );
                    })}
                </InfoCardsList>
            )}
        </div>
    );
};

export default memo(ShelfInfoBlocks);
