import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import { IReferralData } from "@schemas/referral";

const url = API_URLS.getReferralData;

export class ReferralAPI {
    static async getReferralData(): AxiosPromise<IReferralData> {
        return Request.get(`${url}`).then((response) => {
            if (!response.data.success) throw new Error(response.data.message);
            return response.data;
        });
    }
}
