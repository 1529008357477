import { ReactNode } from "react";

import SunSpinner from "@atoms/SunSpinner";

import { SuccessSimpleSVG } from "@svg/SuccessSimpleSVG";

import { TButtonStatus } from "./Button.types";

export const childByStatus: Record<TButtonStatus, ReactNode> = {
    none: null,
    claim: "Claim",
    start: "Start",
    loading: <SunSpinner height="20px" width="20px" />,
    done: <SuccessSimpleSVG />,
} as const;
