import { memo, useEffect } from "react";

import clsx from "clsx";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import { lootboxesSelector } from "@redux/lootboxes/lootboxes.selectors";
import { getPerks } from "@redux/lootboxes/lootboxes.thunk";

import styles from "./AvailablePerks.module.scss";

const AvailablePerks = () => {
    const dispatch = useAppDispatch();

    const {
        lootBoxId,
        perks,
        isLoading: isLootBoxesLoading,
    } = useAppSelector(lootboxesSelector);

    useEffect(() => {
        if (!lootBoxId || perks) {
            return;
        }

        dispatch(getPerks({ lootBoxId }));
    }, [dispatch, lootBoxId, perks]);

    if (isLootBoxesLoading) {
        return <StandardSunSpinner />;
    }

    return (
        <WindowBlock className={styles.wrapper}>
            <Typography className={styles.title} variant="textBold">
                Available perks
            </Typography>

            <div className={styles.list}>
                <div className={styles.head}>
                    <div className={styles.row}>
                        <Typography
                            className={clsx(styles.perks, styles.perks_head)}
                            variant="text2Regular"
                        >
                            Perks
                        </Typography>
                        <Typography
                            className={clsx(styles.amount, styles.amount_head)}
                            variant="text2Regular"
                        >
                            Amount
                        </Typography>
                    </div>
                </div>
                <div className={styles.body}>
                    {/* {availablePerksMock.map(({ amount, perk }, index) => (
                        <div key={index} className={styles.row}>
                            <Typography
                                className={clsx(
                                    styles.perks,
                                    styles.perks_body
                                )}
                                variant="text2Bold"
                            >
                                {perk}
                            </Typography>
                            <Typography
                                className={clsx(
                                    styles.amount,
                                    styles.amount_body
                                )}
                                variant="text2Regular"
                            >
                                {amount}
                            </Typography>
                        </div>
                    ))} */}

                    {perks?.map(({ title, amounts }, index) => (
                        <div key={index} className={styles.row}>
                            <Typography
                                className={clsx(
                                    styles.perks,
                                    styles.perks_body
                                )}
                                variant="text2Bold"
                            >
                                {title}
                            </Typography>
                            <Typography
                                className={clsx(
                                    styles.amount,
                                    styles.amount_body
                                )}
                                variant="text2Regular"
                            >
                                {amounts?.join(" / ")}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        </WindowBlock>
    );
};

export default memo(AvailablePerks);
