import { createAsyncThunk } from "@reduxjs/toolkit";

import { BoostAPI } from "@services/endpoints/earn/Boosts";

export const getBoostList = createAsyncThunk("boosts/", async () => {
    const response = await BoostAPI.getBoosts();
    return response.data;
});

export const activateBoost = createAsyncThunk(
    "boosts/activate/",
    async (boostId: number) => {
        const response = await BoostAPI.activateBoost(boostId);
        return response.data;
    }
);
