import { createSlice } from "@reduxjs/toolkit";

import notifications from "@utils/notifications";

import { activateBoost, getBoostList } from "./boost.thunk";

export interface IBoostState {
    id: number;
    title: string;
    description: string;
    spendType: string;
    spendAmount: number;

    receiveType: string;
    receiveAmount: number;
}

export interface IBoostsState {
    boosts: IBoostState[] | null;
    isLoading: boolean;
    isLoadingActivateBoost: boolean;
}

const initialState: IBoostsState = {
    boosts: null,
    isLoading: false,
    isLoadingActivateBoost: false,
};

export const boostsSlice = createSlice({
    initialState,
    name: "boosts",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBoostList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBoostList.fulfilled, (state, action) => {
                const { payload } = action;
                const boosts = payload.map((_) => {
                    return {
                        ..._,
                        id: Number(_.id),
                        spendAmount: Number(_.spendAmount),
                        receiveAmount: Number(_.receiveAmount),
                    };
                });
                state.boosts = boosts;
                state.isLoading = false;
            })
            .addCase(getBoostList.rejected, (state, action) => {
                state.isLoading = false;
                const { error } = action;
                // eslint-disable-next-line no-console
                console.error(error.message);
            })

            .addCase(activateBoost.pending, (state) => {
                state.isLoadingActivateBoost = true;
            })
            .addCase(activateBoost.fulfilled, (state) => {
                state.isLoadingActivateBoost = false;
                notifications.createSuccess("You’ve got 1 extra game pass!");
            })
            .addCase(activateBoost.rejected, (state, action) => {
                state.isLoadingActivateBoost = false;
                const { error } = action;
                // eslint-disable-next-line no-console
                console.error(error.message);
            });
    },
});

// export const actions = referralSlice.actions;

export default boostsSlice.reducer;
