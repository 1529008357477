import { createSlice } from "@reduxjs/toolkit";

export interface IAppState {
    hasSomethingWentWrongError: boolean;
    hasServerNotAvailableError: boolean;
}

const initialState: IAppState = {
    hasSomethingWentWrongError: false,
    hasServerNotAvailableError: false,
};

export const appSlice = createSlice({
    initialState,
    name: "app",
    reducers: {
        setSomethingWentWrongError: (state, { payload }) => {
            state.hasSomethingWentWrongError = payload;
        },
        setServerNotAvailableError: (state, { payload }) => {
            state.hasServerNotAvailableError = payload;
        },
    },
});

export const { setServerNotAvailableError, setSomethingWentWrongError } =
    appSlice.actions;

export default appSlice.reducer;
