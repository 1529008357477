import { FC } from "react";

import clsx from "clsx";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import styles from "./InfoCard.module.scss";
import { IInfoCard } from "./InfoCard.types";

const InfoCard: FC<IInfoCard> = ({
    button,
    text,
    title,
    icon,
    iconCounter,
    buttonProps,
    className,
    contentClassName,
    onClick,
}) => {
    return (
        <WindowBlock
            className={clsx(styles.wrapper, className)}
            onClick={onClick}
        >
            {icon && (
                <div className={styles.icon}>
                    {icon}
                    {iconCounter && (
                        <Typography
                            className={styles.counter}
                            variant="textSmall"
                        >
                            {iconCounter}
                        </Typography>
                    )}
                </div>
            )}

            <div className={clsx(styles.content, contentClassName)}>
                <Typography className={styles.title} variant="text2Bold">
                    {title}
                </Typography>
                <Typography className={styles.text} variant="text2Regular">
                    {text}
                </Typography>
            </div>

            {button}
            {!button && buttonProps && (
                <Button
                    autoSize
                    variant="gradient"
                    {...buttonProps}
                    className={clsx(styles.button, buttonProps.className)}
                >
                    {buttonProps.text}
                </Button>
            )}
        </WindowBlock>
    );
};

export default InfoCard;
