import clsx from "clsx";

import styles from "./IconBlock.module.scss";
import { IIconBlock } from "./IconBlock.types";

const IconBlock: FCC<IIconBlock> = ({
    children,
    className,
    mode,
    fullSize = false,
    borderRadius = "small",
}) => {
    return (
        <div
            className={clsx(
                styles.wrapper,
                styles[`wrapper_${mode}`],
                className,
                {
                    [styles["wrapper_full-size"]]: fullSize,
                    [styles["wrapper_radius-big"]]: borderRadius === "big",
                }
            )}
        >
            {children}
        </div>
    );
};

export default IconBlock;
