export interface IGetNickName {
    firstName?: string;
    lastName?: string;
    username?: string;
}

export const getNickName = (data: IGetNickName) => {
    const nickName = data.username
        ? data.username
        : `${data.firstName} ${data.lastName}`;
    return nickName;
};
