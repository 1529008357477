import { memo, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { useManageModal } from "@hooks/useManageModal";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock/IconBlock";
import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";
import Typography from "@atoms/Typography";

import InviteFrenModal from "@organisms/Modals/InviteFrenModal";
import MainLayout from "@organisms/layouts/MainLayout";

import { ArrowRightSVG } from "@svg/ArrowRigthSVG";
import { ChartSVG } from "@svg/ChartSVG";
import { CrownSVG } from "@svg/CrownSVG";
import { PersonSVG } from "@svg/PersonSVG";
import { TicketSVG } from "@svg/TicketSVG";

import { referralSelector } from "@redux/referral/referral.selectors";
import { getReferralData } from "@redux/referral/referral.thunk";

import { copyToClipboard } from "@utils/copy";
import notifications from "@utils/notifications";
import { telegramUtils } from "@utils/telegram";

import styles from "./FrensScreen.module.scss";
import FrensList from "./components/FrensList";
import InfoBlock from "./components/InfoBlock";
import MainBlock from "./components/MainBlock";

const FrensScreen = () => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const referral = useAppSelector(referralSelector);

    useEffect(() => {
        if (!referral.referralLink) {
            dispatch(getReferralData());
        }
    }, [dispatch, referral.referralLink]);

    const { onCloseHandler, onOpenHandler, open } = useManageModal();

    const onCopyLinkHandler = () => {
        // TODO: вставить ссылку
        copyToClipboard(referral.referralLink);
        notifications.createSuccess("Refferal link is copied");
    };

    const onCopyLinkInModalHandler = () => {
        onCopyLinkHandler();
        onCloseHandler();
    };

    const onClickSendInModalHandler = () => {
        const url = referral.referralLink;
        const text = "Join me on Tap Rush and let's earn together! 🙌"
            .split(" ")
            .join("\u00A0");
        telegramUtils.shareURL(url, text);
    };

    const isReferralLoading = referral.isLoading;

    return (
        <MainLayout
            additionalScrollHeight={100}
            title={
                <>
                    Invite friends <br /> Earn points
                </>
            }
        >
            <div className={styles.content}>
                <InviteFrenModal
                    open={open}
                    onClickSend={onClickSendInModalHandler}
                    onClose={onCloseHandler}
                    onCopyToClipboard={onCopyLinkInModalHandler}
                />
                {isReferralLoading ? (
                    <StandardSunSpinner />
                ) : (
                    <>
                        <div className={styles.info}>
                            <InfoBlock
                                icon={
                                    <IconBlock mode="orange">
                                        <PersonSVG />
                                    </IconBlock>
                                }
                                mode="orange"
                                name="Frens"
                            >
                                {referral.count}
                            </InfoBlock>
                            <InfoBlock
                                icon={
                                    <IconBlock mode="purple">
                                        <ChartSVG />
                                    </IconBlock>
                                }
                                mode="purple"
                                name="Points"
                            >
                                {referral.earn}
                            </InfoBlock>
                            <InfoBlock
                                icon={
                                    <IconBlock mode="gray">
                                        <TicketSVG />
                                    </IconBlock>
                                }
                                mode="gray"
                                name="Game pass"
                            >
                                {referral.earnGamePasses
                                    ? `+${referral.earnGamePasses}`
                                    : 0}
                            </InfoBlock>
                        </div>
                        <MainBlock
                            referral={referral}
                            onCopyLink={onCopyLinkHandler}
                        />
                    </>
                )}

                <Button
                    className={styles.top}
                    onClick={() => navigate(routes.leaderboard)}
                >
                    <IconBlock
                        className={styles["crown-block"]}
                        mode="gradient"
                    >
                        <CrownSVG height={20} width={20} />
                    </IconBlock>

                    <Typography variant="captionRegular">
                        Top 100 leaders
                    </Typography>

                    <ArrowRightSVG className={styles.arrow} />
                </Button>

                <FrensList />

                <Button
                    className={styles.invite}
                    variant="gradient"
                    onClick={onOpenHandler}
                >
                    Invite a fren
                </Button>
            </div>
        </MainLayout>
    );
};

export default memo(FrensScreen);
