import { memo, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import Button from "@atoms/Button";
import SunSpinner from "@atoms/SunSpinner";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import MainLayout from "@organisms/layouts/MainLayout";

import { PointsSVG } from "@svg/PointsSVG";

import { userSelector } from "@redux/users/users.selectors";
import { getUserInfo, startUserGame } from "@redux/users/users.thunk";

import { getTimeUntilUpdate } from "@utils/refreshDate";

import style from "./PlayScreen.module.scss";
import PassCounter from "./components/PassCounter";
import PassEarner from "./components/PassEarner";
import PassTimeCounter from "./components/PassTimeCounter";

const PlayScreen = () => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const users = useAppSelector(userSelector);
    const [currentCounter, setCurrentCounter] = useState<string>("00:00:01");

    useEffect(() => {
        const { userData } = users;
        if (!userData) return;

        setCurrentCounter(
            getTimeUntilUpdate(
                userData.createdAt,
                userData.refreshGamePassesOffset,
                userData.refreshGamePassesMinute
            )
        );

        const intervalId = setInterval(() => {
            setCurrentCounter(
                getTimeUntilUpdate(
                    userData.createdAt,
                    userData.refreshGamePassesOffset,
                    userData.refreshGamePassesMinute
                )
            );
        }, 1000);

        return () => clearInterval(intervalId);
    }, [users]);

    useEffect(() => {
        if (currentCounter !== "00:00:00") return;

        setTimeout(async () => {
            dispatch(getUserInfo());
        }, 5_000);
    }, [currentCounter, dispatch]);

    return (
        <MainLayout
            additionalScrollHeight={100}
            subtitle={
                <Typography variant="textRegular">
                    Play the game, earn points and get <br /> the rewards from
                    the shelf!
                </Typography>
            }
            title={
                <div className={style.pointsLabel}>
                    <div className={style.content}>
                        <PointsSVG className={style.pointIcon} />
                        <div className={style.pointsText}>
                            {!users.isLoading ? (
                                <Typography variant="h1Bold">
                                    {users.userData?.points}
                                </Typography>
                            ) : (
                                <SunSpinner height="30px" width="30px" />
                            )}
                            <Typography
                                style={{ marginBottom: 4 }}
                                variant="textRegular"
                            >
                                POINTS
                            </Typography>
                        </div>
                    </div>
                </div>
            }
        >
            {currentCounter && (
                <PassTimeCounter
                    isLoading={users.isLoading}
                    targetDate={currentCounter}
                />
            )}

            <PassCounter
                isLoading={users.isLoading}
                passes={
                    Number(users.userData?.gamePasses) +
                    Number(users.userData?.extraGamePasses)
                }
            />

            {Number(users.userData?.gamePasses) +
                Number(users.userData?.extraGamePasses) ===
                0 && <PassEarner />}

            <WindowBlock className={style.gameContainer}>
                <div className={style.gameBlock} />
                <div className={style.description}>
                    <div className={style.gameInfo}>
                        <Typography
                            style={{ color: "#4B4F61" }}
                            variant="text2Regular"
                        >
                            Game
                        </Typography>
                        <Typography variant="h3Bold">Tap rush</Typography>
                    </div>

                    <Button
                        className={style.playButton}
                        disabled={
                            Number(users.userData?.gamePasses) +
                                Number(users.userData?.extraGamePasses) ===
                            0
                        }
                        variant="gradient"
                        onClick={() => {
                            dispatch(startUserGame()).then((res: any) => {
                                const gameId = res.payload.data.data.game.id;

                                if (gameId !== undefined) {
                                    navigate(`${routes.game}?gameId=${gameId}`);
                                }
                            });
                        }}
                    >
                        Play
                    </Button>
                </div>
            </WindowBlock>
        </MainLayout>
    );
};

export default memo(PlayScreen);
