import { createAsyncThunk } from "@reduxjs/toolkit";

import { LootBoxAPI } from "@services/endpoints/earn/LootBox";

export const getLootBoxData = createAsyncThunk("lootBox/", async () => {
    const response = await LootBoxAPI.getLootBoxData();
    return response.data;
});

export const openLootBox = createAsyncThunk(
    "lootBox/open/",
    async ({ lootBoxId, count }: { lootBoxId: number; count?: number }) => {
        const response = await LootBoxAPI.openLootBox({ lootBoxId, count });
        return response.data;
    }
);

export const getPerks = createAsyncThunk(
    "lootBox/items/",
    async ({ lootBoxId }: { lootBoxId: number }) => {
        const response = await LootBoxAPI.getLootBoxPerks({ lootBoxId });
        return response.data;
    }
);
