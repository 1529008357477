import toast from "react-hot-toast";

export enum ENotifications {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

class Notifications {
    public createSuccess(text: string) {
        toast.success(text);
    }

    public createError(text: string) {
        toast.error(text);
    }
}

export default new Notifications();
