import { createSlice } from "@reduxjs/toolkit";

import { IUserData } from "@schemas/users";

import { getUserInfo, initUserToken, startUserGame } from "./users.thunk";

export interface IUsersState {
    isAuth: boolean;
    isLoading: boolean;
    userData: IUserData | null;
    gameId: any;
}

const initialState: IUsersState = {
    isLoading: false,
    isAuth: false,
    userData: null,
    gameId: null,
};

export const userSlice = createSlice({
    initialState,
    name: "earn",
    reducers: {
        setAuth(state) {
            state.isAuth = true;
        },
        setExit(state) {
            state.isAuth = false;
        },
        updateUserData(state, { payload }) {
            if (!state.userData) return;
            state.userData = { ...state.userData, ...payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.userData = action.payload.data?.data;
                state.isLoading = false;
            })
            .addCase(getUserInfo.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(startUserGame.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(startUserGame.fulfilled, (state, action) => {
                state.gameId = action.payload.data?.data?.game?.gameId;
                state.isLoading = false;
            })
            .addCase(startUserGame.rejected, (state) => {
                state.isLoading = false;
            });

        builder
            .addCase(initUserToken.fulfilled, (state) => {
                state.isAuth = true;
                state.isLoading = false;
            })
            .addCase(initUserToken.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(initUserToken.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { setAuth, setExit, updateUserData } = userSlice.actions;

export default userSlice.reducer;
