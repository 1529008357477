import { routes } from "@constants/routes";

import { EarnSVG } from "@svg/EarnSVG";
import { FrensSVG } from "@svg/FrensSVG";
import { PlaySVG } from "@svg/PlaySVG";
import { ShelfSVG } from "@svg/ShelfSVG";

import { INavItem } from "./Navbar.types";

export const navItems: INavItem[] = [
    {
        icon: PlaySVG,
        path: routes.play,
        title: "Play",
    },
    {
        icon: EarnSVG,
        path: routes.earn,
        title: "Earn",
    },
    {
        icon: ShelfSVG,
        path: routes.shelf,
        title: "Shelf",
    },
    {
        icon: FrensSVG,
        path: routes.frens,
        title: "Frens",
    },
] as const;

export const navActiveItemGradient = ["#FFB392", "#FF7134"];
