import { FC } from "react";

import { ISvg } from "./SVG.types";

export const TicketSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="12"
            viewBox="0 0 12 12"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.875 4.78125C10.9496 4.78125 11.0211 4.75162 11.0739 4.69887C11.1266 4.64613 11.1562 4.57459 11.1562 4.5V3C11.1562 2.82595 11.0871 2.65903 10.964 2.53596C10.841 2.41289 10.674 2.34375 10.5 2.34375H1.5C1.32595 2.34375 1.15903 2.41289 1.03596 2.53596C0.91289 2.65903 0.84375 2.82595 0.84375 3V4.5C0.84375 4.57459 0.873382 4.64613 0.926126 4.69887C0.978871 4.75162 1.05041 4.78125 1.125 4.78125C1.44823 4.78125 1.75823 4.90965 1.98679 5.13821C2.21535 5.36677 2.34375 5.67677 2.34375 6C2.34375 6.32323 2.21535 6.63323 1.98679 6.86179C1.75823 7.09035 1.44823 7.21875 1.125 7.21875C1.05041 7.21875 0.978871 7.24838 0.926126 7.30113C0.873382 7.35387 0.84375 7.42541 0.84375 7.5V9C0.84375 9.17405 0.91289 9.34097 1.03596 9.46404C1.15903 9.58711 1.32595 9.65625 1.5 9.65625H10.5C10.674 9.65625 10.841 9.58711 10.964 9.46404C11.0871 9.34097 11.1562 9.17405 11.1562 9V7.5C11.1562 7.42541 11.1266 7.35387 11.0739 7.30113C11.0211 7.24838 10.9496 7.21875 10.875 7.21875C10.5518 7.21875 10.2418 7.09035 10.0132 6.86179C9.78465 6.63323 9.65625 6.32323 9.65625 6C9.65625 5.67677 9.78465 5.36677 10.0132 5.13821C10.2418 4.90965 10.5518 4.78125 10.875 4.78125ZM1.40625 9V7.75922C1.82502 7.69274 2.20633 7.47905 2.48165 7.15658C2.75696 6.83411 2.90821 6.42401 2.90821 6C2.90821 5.57599 2.75696 5.16589 2.48165 4.84342C2.20633 4.52095 1.82502 4.30726 1.40625 4.24078V3C1.40625 2.97514 1.41613 2.95129 1.43371 2.93371C1.45129 2.91613 1.47514 2.90625 1.5 2.90625H4.21875V9.09375H1.5C1.47514 9.09375 1.45129 9.08387 1.43371 9.06629C1.41613 9.04871 1.40625 9.02486 1.40625 9ZM10.5938 7.75922V9C10.5938 9.02486 10.5839 9.04871 10.5663 9.06629C10.5487 9.08387 10.5249 9.09375 10.5 9.09375H4.78125V2.90625H10.5C10.5249 2.90625 10.5487 2.91613 10.5663 2.93371C10.5839 2.95129 10.5938 2.97514 10.5938 3V4.24078C10.175 4.30726 9.79367 4.52095 9.51835 4.84342C9.24304 5.16589 9.09179 5.57599 9.09179 6C9.09179 6.42401 9.24304 6.83411 9.51835 7.15658C9.79367 7.47905 10.175 7.69274 10.5938 7.75922Z"
                fill="white"
            />
        </svg>
    );
};
