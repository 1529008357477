export const routes = {
    earn: "/earn",
    frens: "/frens",
    game: "/game",
    leaderboard: `/frens/leaderboard`,
    perks: "/shelf/perks",
    play: "/",
    oops: "/oops",
    results: "/results",
    shelf: "/shelf",
    hardLoad: "/hard-load",
    welcome: "welcome/",
} as const;
