import { FC } from "react";

export interface ICustomGradientSVG {
    gradientColors: string[];
    gradientId: string;
}

export const CustomGradientSVG: FC<ICustomGradientSVG> = ({
    gradientColors,
    gradientId,
}) => {
    return (
        <svg height={0} width={0}>
            <defs>
                <linearGradient id={gradientId}>
                    {gradientColors.map((color, index) => (
                        <stop
                            key={index}
                            offset={`${
                                index * (100 / (gradientColors.length - 1))
                            }%`}
                            stopColor={color}
                        />
                    ))}
                </linearGradient>
            </defs>
        </svg>
    );
};
