import { useEffect, useState } from "react";

export const useOrientation = () => {
    const [orientation, setOrientation] = useState(
        window.matchMedia("(orientation: portrait)").matches
            ? "portrait"
            : "landscape"
    );

    useEffect(() => {
        const portraitMediaQuery = window.matchMedia("(orientation: portrait)");
        const landscapeMediaQuery = window.matchMedia(
            "(orientation: landscape)"
        );

        const handleOrientationChange = () => {
            if (portraitMediaQuery.matches) {
                setOrientation("portrait");
            } else if (landscapeMediaQuery.matches) {
                setOrientation("landscape");
            }
        };

        portraitMediaQuery.addEventListener("change", handleOrientationChange);
        landscapeMediaQuery.addEventListener("change", handleOrientationChange);

        // Cleanup the event listeners on component unmount
        return () => {
            portraitMediaQuery.removeEventListener(
                "change",
                handleOrientationChange
            );
            landscapeMediaQuery.removeEventListener(
                "change",
                handleOrientationChange
            );
        };
    }, []);

    return orientation;
};
