import { configureStore } from "@reduxjs/toolkit";

import appReducer from "@redux/app/app.slice";
import boostReducer from "@redux/boost/boost.slice";
import earnReducer from "@redux/earn/earn.slice";
import frensReducer from "@redux/frens/frens.slice";
import leaderBoardsReducer from "@redux/leaderboards/leaderboards.slice";
import lootBoxReducer from "@redux/lootboxes/lootboxes.slice";
import perksReducer from "@redux/perks/perks.slice";
import referralReducer from "@redux/referral/referral.slice";
import userReducer from "@redux/users/users.slice";

export const store = configureStore({
    reducer: {
        earn: earnReducer,
        users: userReducer,
        referral: referralReducer,
        frens: frensReducer,
        perks: perksReducer,
        leaderBoards: leaderBoardsReducer,
        boost: boostReducer,
        lootboxes: lootBoxReducer,
        app: appReducer,
    },
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
