import { FC } from "react";

import { ISvg } from "./SVG.types";

export const TelegramSVG: FC<ISvg> = ({ className }) => {
    return (
        <svg
            className={className}
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.4039 2.16402C18.3285 2.09892 18.2368 2.05564 18.1386 2.03884C18.0405 2.02204 17.9396 2.03237 17.8469 2.06871L2.01405 8.26481C1.82147 8.34016 1.65854 8.47596 1.54972 8.6518C1.4409 8.82765 1.39208 9.03406 1.41057 9.24002C1.42906 9.44599 1.51387 9.6404 1.65228 9.79405C1.79068 9.9477 1.97519 10.0523 2.17811 10.0921L6.40624 10.9226V15.625C6.40632 15.843 6.47155 16.056 6.59356 16.2366C6.71556 16.4173 6.88876 16.5574 7.09095 16.6389C7.29313 16.7204 7.51506 16.7397 7.72826 16.6942C7.94146 16.6487 8.1362 16.5405 8.28749 16.3836L10.3687 14.225L13.6172 17.0726C13.8155 17.2481 14.0711 17.3451 14.3359 17.3453C14.4516 17.345 14.5665 17.3268 14.6765 17.2914C14.857 17.2343 15.0194 17.1308 15.1474 16.9914C15.2754 16.852 15.3647 16.6815 15.4062 16.4968L18.5797 2.70074C18.602 2.60375 18.5974 2.50252 18.5664 2.40794C18.5355 2.31336 18.4793 2.22902 18.4039 2.16402ZM2.34764 9.16949C2.34465 9.16143 2.34465 9.15256 2.34764 9.14449C2.35117 9.14177 2.35513 9.13966 2.35936 9.13824L14.8367 4.25387L6.76639 10.0351L2.35936 9.17262L2.34764 9.16949ZM7.61249 15.732C7.59095 15.7543 7.56326 15.7698 7.53292 15.7763C7.50259 15.7828 7.471 15.7802 7.44217 15.7687C7.41334 15.7572 7.38858 15.7374 7.37105 15.7118C7.35352 15.6862 7.34401 15.656 7.34374 15.625V11.5711L9.66327 13.6023L7.61249 15.732ZM14.493 16.2851C14.4872 16.3115 14.4744 16.3359 14.456 16.3557C14.4376 16.3755 14.4142 16.39 14.3883 16.3976C14.3618 16.4069 14.3334 16.4089 14.3059 16.4034C14.2784 16.3979 14.2529 16.3851 14.232 16.3664L7.62811 10.5734L17.4219 3.55465L14.493 16.2851Z"
                fill="white"
            />
        </svg>
    );
};
