import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserAPI } from "@services/endpoints/earn/User";

export const initUserToken = createAsyncThunk("users/token", async () => {
    const response = await UserAPI.initToken();

    return response;
});

export const getUserInfo = createAsyncThunk("users/info", async () => {
    const response = await UserAPI.getUserInfo();

    return response;
});

export const startUserGame = createAsyncThunk("users/start", async () => {
    const response = await UserAPI.startUserGame();

    return response;
});

export const finishUserGame = createAsyncThunk(
    "users/finish",
    async (id: any) => {
        const response = await UserAPI.finishUserGame(id);

        return response;
    }
);
