import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import { IPerksData } from "./dto/perks.types";

const url = API_URLS.perks;

export class PERKS_API {
    static async getPerksData(): AxiosPromise<IPerksData[]> {
        return Request.get(`${url}`).then((response) => {
            if (!response.data.success) throw new Error(response.data.message);
            return response.data;
        });
    }

    static async claimPerk(id: string) {
        return Request.post(`${url}${id}/claim/`);
    }
}
