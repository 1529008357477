import { forwardRef } from "react";

import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";

import { routes } from "@constants/routes";

import { useGetCustomGradient } from "@hooks/useGetCustomGradient";

import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import { NavShadowSVG, navFilterId } from "@svg/shadows/NavShadowSVG";

import { navActiveItemGradient, navItems } from "./Navbar.const";
import styles from "./Navbar.module.scss";

const Navbar = forwardRef<HTMLDivElement>((_, ref) => {
    const { pathname } = useLocation();

    const { CustomGradientSVG, gradientId } = useGetCustomGradient(
        navActiveItemGradient
    );

    return (
        <>
            {CustomGradientSVG}
            <NavShadowSVG />
            <WindowBlock className={styles.nav}>
                <div ref={ref} className={styles.content}>
                    {navItems.map(({ icon: Icon, path, title }) => {
                        const isHomeRoute = path === routes.play;
                        const isActivePath = isHomeRoute
                            ? pathname === path
                            : pathname.startsWith(path);

                        return (
                            <Link
                                key={title}
                                className={clsx(styles.item, {
                                    [styles.item_active]: isActivePath,
                                })}
                                to={path}
                            >
                                <Icon
                                    color={
                                        !isActivePath ? "#696E85" : undefined
                                    }
                                    filterId={
                                        isActivePath ? navFilterId : undefined
                                    }
                                    gradientId={
                                        isActivePath ? gradientId : undefined
                                    }
                                />

                                <Typography className={styles.name}>
                                    {title}
                                </Typography>
                            </Link>
                        );
                    })}
                </div>
            </WindowBlock>
        </>
    );
});

export default Navbar;
