import { createSlice } from "@reduxjs/toolkit";

import { IPerksData } from "@services/endpoints/earn/dto/perks.types";

import { getPerksData } from "./perks.thunk";

export interface IPerksState {
    isLoading: boolean;
    perks: IPerksData[] | null;
}

const initialState: IPerksState = {
    isLoading: false,
    perks: null,
};

export const perksSlice = createSlice({
    initialState,
    name: "perks",
    reducers: {
        setPerks(state, payload) {
            console.log(payload.payload);

            state.perks = payload.payload;
        },
        setStatus(state, payload) {
            const { id, status } = payload.payload;

            state.perks?.map((el) => {
                if (el.id === id) {
                    el.status = status;
                }
                return el;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPerksData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPerksData.fulfilled, (state, action) => {
                state.perks = action.payload;

                state.isLoading = false;
            })
            .addCase(getPerksData.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

// export const actions = referralSlice.actions;

export default perksSlice.reducer;
