import { createAsyncThunk } from "@reduxjs/toolkit";

import { FrensAPI, ILimitOffset } from "@services/endpoints/earn/Frens";

export const getFrensList = createAsyncThunk(
    "referral/frens/list/",
    async ({ limit, offset }: ILimitOffset) => {
        const response = await FrensAPI.getFrensList({ limit, offset });
        return response.data;
    }
);
