import { FC, memo } from "react";

import clsx from "clsx";

import styles from "./SunSpinner.module.scss";
import { ISunSpinner } from "./SunSpinner.types";

const SunSpinner: FC<ISunSpinner> = ({
    className,
    height,
    width,
    horizontalCentered,
}) => {
    return (
        <div
            className={clsx(styles.wrapper, className, {
                [styles["wrapper_horizontal-center"]]: !!horizontalCentered,
            })}
            style={{ width, height }}
        >
            {[...Array(8)].map((_, index) => (
                <div
                    key={index}
                    className={clsx(styles.bar, styles[`bar_${index}`])}
                />
            ))}
        </div>
    );
};

export default memo(SunSpinner);
