import { FC, memo } from "react";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import bgImg from "@images/frensMainBg.png";
import img from "@images/frensMainImg.png";

import { CopySvg } from "@svg/CopySVG";
import { TicketSVG } from "@svg/TicketSVG";

import styles from "./MainBlock.module.scss";
import { IMainBlock } from "./MainBlock.types";

const MainBlock: FC<IMainBlock> = ({ onCopyLink, referral }) => {
    return (
        <WindowBlock className={styles.main}>
            <img alt="" className={styles.bg} src={bgImg} />
            <img alt="" className={styles.img} src={img} />

            <div className={styles.content}>
                <Typography className={styles.title} variant="h3Bold">
                    Reward yourself and help out your friends!
                </Typography>
                <div className={styles.text}>
                    <Typography variant="captionRegular">
                        Score 10% from boddies
                    </Typography>
                    <Typography
                        className={styles.info}
                        variant="captionRegular"
                    >
                        Get +1 game pass <TicketSVG height={14} width={14} />{" "}
                        for each fren
                    </Typography>
                </div>
                <div className={styles.link}>
                    <Typography
                        className={styles["link-text"]}
                        variant="captionRegular"
                    >
                        {referral.referralLink.replace("https://", "")}
                    </Typography>
                    <Button
                        autoSize
                        className={styles["link-button"]}
                        onClick={onCopyLink}
                    >
                        <IconBlock fullSize mode="gradient-revert">
                            <CopySvg />
                        </IconBlock>
                    </Button>
                </div>
            </div>
        </WindowBlock>
    );
};

export default memo(MainBlock);
