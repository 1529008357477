import { createAsyncThunk } from "@reduxjs/toolkit";

import { PERKS_API } from "@services/endpoints/earn/Perks";

export const getPerksData = createAsyncThunk(
    "referral/perks/list/",
    async () => {
        const response = await PERKS_API.getPerksData();
        return response.data;
    }
);

export const claimPerk = createAsyncThunk(
    "referral/perks/claim/",
    async (id: string) => {
        const response = await PERKS_API.claimPerk(id);
        return response.data;
    }
);
