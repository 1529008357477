import { FC } from "react";

import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import style from "./BadOrientationScreen.module.scss";

const BadOrientationScreen: FC = () => {
    return (
        <MainLayout additionalScrollHeight={100} hasNavbar={false} title="">
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <Typography className={style.textBold} variant="h1Bold">
                    This orientation is not supported
                </Typography>
                <Typography className={style.textRegular} variant="textRegular">
                    Please rotate back your device
                </Typography>
            </div>
        </MainLayout>
    );
};

export default BadOrientationScreen;
