import { FC, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import HardLoadSVG from "@svg/HardLoadSVG";

import style from "./HardLoadScreen.module.scss";

const HardLoadScreen: FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const goToPlayScreen = () => navigate(routes.play);

        window.addEventListener("load", goToPlayScreen);

        return () => {
            window.removeEventListener("load", goToPlayScreen);
        };
    }, [navigate]);

    return (
        <MainLayout additionalScrollHeight={100} hasNavbar={false} title="">
            <div className={style.warningContainer}>
                <HardLoadSVG />
            </div>
            <Typography className={style.textBold} variant="h1Bold">
                Heavy load <br /> detected
            </Typography>
            <Typography className={style.textRegular} variant="textRegular">
                The app is currently experiencing <br /> increased load due to
                the number of <br /> players.
            </Typography>
            <Typography className={style.textRegular} variant="textRegular">
                Try to come back in 10 minutes
            </Typography>
        </MainLayout>
    );
};

export default HardLoadScreen;
