/* eslint-disable no-nested-ternary */
import { memo, useEffect } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";
import Typography from "@atoms/Typography";

import InfoCard from "@molecules/InfoCard";
import InfoCardsList from "@molecules/InfoCardsList";

import MainLayout from "@organisms/layouts/MainLayout";

import { perksSelector } from "@redux/perks/perks.selectors";
import { perksSlice } from "@redux/perks/perks.slice";
import { claimPerk, getPerksData } from "@redux/perks/perks.thunk";
import { updateUserData } from "@redux/users/users.slice";

import notifications from "@utils/notifications";

import styles from "./YourPerksScreen.module.scss";

const YourPerksScreen = () => {
    const dispath = useAppDispatch();
    const perks = useAppSelector(perksSelector);

    const handleClaimClick = (id: string) => {
        dispath(claimPerk(id)).then((res) => {
            const {
                success,
                data: { user },
            } = res.payload;
            if (success) {
                // notifications.createSuccess("You`ve claimed success!");

                dispath(
                    perksSlice.actions.setStatus({
                        id: res.payload.data.item.id,
                        status: res.payload.data.item.status,
                    })
                );

                if (user) {
                    dispath(updateUserData(user));
                }
            } else {
                notifications.createError(res.payload.message);
            }
        });
    };

    useEffect(() => {
        dispath(getPerksData());
    }, [dispath]);

    return (
        <MainLayout withGoBack title="Your perks list">
            <div className={styles.wrapper}>
                {perks.perks?.length === 0 && (
                    <Typography className={styles.empty} variant="textRegular">
                        No perks yet
                    </Typography>
                )}
                {perks.isLoading && <StandardSunSpinner />}
                <InfoCardsList className={styles.boosts} title="">
                    {perks.perks?.map((el, index) => {
                        return (
                            <InfoCard
                                key={index}
                                buttonProps={{
                                    onClick: () => {
                                        if (el.status === "Created")
                                            handleClaimClick(el.id);
                                    },
                                    status:
                                        el.status === "Pending"
                                            ? "loading"
                                            : el.status === "Claimed"
                                            ? "done"
                                            : "none",
                                    text: "Claim",
                                }}
                                text={new Date(el.createdAt).toUTCString()}
                                title={`${el.reward} ${el.title}`}
                            />
                        );
                    })}
                </InfoCardsList>
            </div>
        </MainLayout>
    );
};

export default memo(YourPerksScreen);
