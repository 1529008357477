import { FC } from "react";

import { ISvg } from "./SVG.types";

export const CopySvg: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.5 2.125H5.5C5.40054 2.125 5.30516 2.16451 5.23484 2.23483C5.16451 2.30516 5.125 2.40054 5.125 2.5V5.125H2.5C2.40054 5.125 2.30516 5.16451 2.23483 5.23484C2.16451 5.30516 2.125 5.40054 2.125 5.5V13.5C2.125 13.5995 2.16451 13.6948 2.23483 13.7652C2.30516 13.8355 2.40054 13.875 2.5 13.875H10.5C10.5995 13.875 10.6948 13.8355 10.7652 13.7652C10.8355 13.6948 10.875 13.5995 10.875 13.5V10.875H13.5C13.5995 10.875 13.6948 10.8355 13.7652 10.7652C13.8355 10.6948 13.875 10.5995 13.875 10.5V2.5C13.875 2.40054 13.8355 2.30516 13.7652 2.23483C13.6948 2.16451 13.5995 2.125 13.5 2.125ZM10.125 13.125H2.875V5.875H10.125V13.125ZM13.125 10.125H10.875V5.5C10.875 5.40054 10.8355 5.30516 10.7652 5.23484C10.6948 5.16451 10.5995 5.125 10.5 5.125H5.875V2.875H13.125V10.125Z"
                fill="white"
            />
        </svg>
    );
};
