import { AxiosInstance } from "axios";

import { axiosInstance } from "./axios";

class Request {
    protected readonly instance: AxiosInstance;

    public constructor() {
        this.instance = axiosInstance;
        const token = localStorage.getItem("token");

        if (token)
            this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    public setToken = (accessToken: string) => {
        this.instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    };

    public getInstance = () => this.instance;
}

export const request = new Request();

export const { setToken } = request;

export default request.getInstance();
