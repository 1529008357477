import CryptoJS from "crypto-js";

export const encrypt = (points: string, key: string) => {
    const timestamp = Date.now();

    const str = `${points}-${timestamp}`;

    const sign = CryptoJS.AES.encrypt(str, key).toString();

    const obj = JSON.stringify({
        points: Number(points),
        timestamp,
        sign,
    });

    return {
        data: CryptoJS.AES.encrypt(obj, key).toString(),
    };
};
