import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import { IFrensData } from "@schemas/frens";

const url = API_URLS.getFrensList;

export interface ILimitOffset {
    limit?: number;
    offset?: number;
}

export class FrensAPI {
    static async getFrensList({
        limit,
        offset,
    }: ILimitOffset): AxiosPromise<IFrensData[]> {
        return Request.get(`${url}`, { params: { limit, offset } }).then(
            (response) => {
                if (!response.data.success)
                    throw new Error(response.data.message);
                return response.data;
            }
        );
    }
}
