import { FC } from "react";

import { ISvg } from "./SVG.types";

export const CrownSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15.24 4.76186C15.1272 4.66351 14.9877 4.6009 14.8393 4.58199C14.6908 4.56307 14.5401 4.58869 14.4062 4.65561L11.2681 6.21811L8.64812 1.86623C8.58077 1.75456 8.48571 1.66218 8.37216 1.59806C8.2586 1.53394 8.13041 1.50024 8 1.50024C7.86959 1.50024 7.74139 1.53394 7.62784 1.59806C7.51428 1.66218 7.41922 1.75456 7.35187 1.86623L4.73187 6.21998L1.595 4.65748C1.46146 4.59116 1.31131 4.56576 1.16339 4.58447C1.01547 4.60317 0.876369 4.66515 0.763543 4.76262C0.650717 4.86009 0.569193 4.98871 0.5292 5.13235C0.489208 5.27598 0.492529 5.42823 0.538747 5.56998L2.85125 12.655C2.8745 12.7262 2.91344 12.7912 2.96519 12.8453C3.01695 12.8994 3.0802 12.9412 3.15027 12.9676C3.22034 12.994 3.29545 13.0043 3.37004 12.9978C3.44463 12.9913 3.51681 12.9681 3.58125 12.93C3.59687 12.9206 5.195 12 8 12C10.805 12 12.4031 12.9206 12.4175 12.9294C12.482 12.9679 12.5543 12.9914 12.629 12.9981C12.7038 13.0049 12.7792 12.9947 12.8495 12.9683C12.9198 12.942 12.9832 12.9001 13.0352 12.8459C13.0871 12.7916 13.1261 12.7264 13.1494 12.655L15.4619 5.57186C15.5094 5.43006 15.5137 5.27733 15.4742 5.13309C15.4347 4.98885 15.3532 4.85963 15.24 4.76186ZM12.375 11.8019C11.625 11.4837 10.13 11 8 11C5.87 11 4.375 11.4837 3.625 11.8019L1.66937 5.81248L4.48625 7.21686C4.65675 7.30094 4.85256 7.31813 5.03511 7.26503C5.21765 7.21193 5.37369 7.0924 5.4725 6.92998L8 2.72873L10.5275 6.92873C10.6263 7.09099 10.7822 7.21042 10.9646 7.2635C11.147 7.31659 11.3427 7.29951 11.5131 7.21561L14.3306 5.81248L12.375 11.8019Z"
                fill="white"
            />
        </svg>
    );
};
