import { Scene } from "phaser";

import { axiosInstance } from "@services/axios";

import { encrypt } from "@utils/encrypt";

import { SpineGameObject } from "@esotericsoftware/spine-phaser";

import { EventBus } from "../components/molecules/PhaserGame/EventBus";
import { IGameConfig } from "../components/molecules/PhaserGame/types";

export class Game extends Scene {
    private ball: SpineGameObject | null = null;

    private footballGuy: SpineGameObject | null = null;

    private background: Phaser.GameObjects.Sprite | null = null;

    private backgroundSpeedY: number = 10;

    private backgroundSpeedX: number = 10;

    private progressContainer: any = null;

    private heightTitleText: Phaser.GameObjects.Text | null = null;

    private heightCounterText: Phaser.GameObjects.Text | null = null;

    private startText: Phaser.GameObjects.Text | null = null;

    private fpsVersionText: Phaser.GameObjects.Text | null = null;

    private gameOverText: Phaser.GameObjects.Text | null = null;

    private checkResultsText: Phaser.GameObjects.Text | null = null;

    private energy: number = 0;

    private decreaseEnergyCoeff = 10;

    private height: number = 0;

    private checkButton: Phaser.GameObjects.Sprite | null = null;

    private isGameOver: boolean = false;

    private isGameStarted: boolean = false;

    private isAnimStarted: boolean = false;

    private isFinishGame: boolean = true;

    private clouds: any[] = [];

    private stars: any[] = [];

    private planes: any[] = [];

    private baloons: any[] = [];

    private rockets: any[] = [];

    private nlos: any[] = [];

    private isSaturnSpawned: boolean = false;

    private progressBar: Phaser.GameObjects.Sprite | null = null;

    private progressFill: Phaser.GameObjects.Sprite | null = null;

    private gameId: string | null = null;

    private config: IGameConfig = {
        baloons: {
            interval: 5000,
            max: 300,
            min: 100,
            speedX: 0.15,
            speedY: 0.4,
        },
        nlos: {
            interval: 7000,
            max: 1300,
            min: 1100,
            speedX: 0.5,
            speedY: 0.5,
        },
        planes: {
            interval: 7000,
            max: 700,
            min: 400,
            speedX: 0.9,
            speedY: 0.25,
        },
        rockets: {
            interval: 7000,
            max: 1000,
            min: 800,
            speedX: 0.4,
            speedY: 0.5,
        },
        saturn: {
            interval: 20000,
            max: null,
            min: 1450,
            speedX: 1,
            speedY: 1,
        },
        version: "0.0.8",
    };

    constructor() {
        super("Game");
        const searchParams = new URLSearchParams(
            window.location.search.slice(1)
        );
        this.gameId = searchParams.get("gameId");

        if (this.gameId === undefined) {
            const a = document.createElement("a");
            a.href = `/play`;
            a.click();
        }
    }

    create() {
        const { width, height } = this.sys.game.canvas;

        if (this.config.baloons.interval) {
            setInterval(() => {
                if (this.isGameOver || !this.isGameStarted) return;

                if (
                    this.height > this.config.baloons.min &&
                    this.height < this.config.baloons.max!
                )
                    this.spawnBaloons();
            }, this.config.baloons.interval);
        }

        if (this.config.planes.interval) {
            setInterval(() => {
                if (this.isGameOver || !this.isGameStarted) return;

                if (
                    this.height > this.config.planes.min &&
                    this.height < this.config.planes.max!
                )
                    this.spawnPlanes();
            }, this.config.planes.interval);
        }

        if (this.config.rockets.interval) {
            setInterval(() => {
                if (this.isGameOver || !this.isGameStarted) return;

                if (
                    this.height > this.config.rockets.min &&
                    this.height < this.config.rockets.max!
                )
                    this.spawnRockets();
            }, this.config.rockets.interval);
        }

        if (this.config.nlos.interval) {
            setInterval(() => {
                if (this.isGameOver || !this.isGameStarted) return;

                if (this.height > this.config.nlos.min) this.spawnNlo();
            }, this.config.nlos.interval);
        }

        setInterval(() => {
            if (this.isGameOver || !this.isGameStarted) return;

            if (this.height < 800) {
                this.spawnClouds();
            }
        }, 1500);

        setInterval(() => {
            if (this.isGameOver || !this.isGameStarted) return;

            if (this.height > 800) {
                this.cameras.main.setBackgroundColor("#065ba1");

                this.spawnStars();
            }
        }, 800);

        this.background = this.add.sprite(0, 100, "background");

        this.background.setOrigin(0, 0);

        this.centerBackground();

        const containerWidth = 200;
        const containerHeight = 200;

        const containerX = (this.cameras.main.width - containerWidth) / 2;
        const containerY = (this.cameras.main.height - containerHeight) / 2;

        const container = this.add.container(containerX, containerY + 120);

        this.progressContainer = this.add.container(containerX, containerY);

        const counterTextContainer = this.add.container(containerX, containerY);

        const gameOverTextContainer = this.add.container(
            containerX,
            containerY
        );

        container.setSize(containerWidth, containerHeight);

        this.progressContainer.setSize(containerWidth, containerHeight);

        counterTextContainer.setSize(containerWidth, containerHeight);

        gameOverTextContainer.setSize(containerWidth, containerHeight);

        this.physics.world.setBounds(0, 0, width, height);

        this.ball = this.add.spine(0, 0, "ballJson", "ballAtlas");

        this.ball.animationState.setAnimation(0, "ball", true);

        this.ball.setVisible(false);

        container.add(this.ball);

        this.ball.setPosition(200, 0);

        container.setVisible(true);

        this.progressBar = this.add.sprite(-15, 45, "progressBg");
        this.progressBar.setScale(0.55);

        const icon = this.add.sprite(-75, 45, "barIcon");
        icon.setDepth(1);
        icon.setScale(0.7);

        this.progressFill = this.add.sprite(-92, 45, "progressFill");
        this.progressFill.setScale(0.46, 0.55);
        this.progressFill.setOrigin(0, 0.5);

        this.progressContainer.add([this.progressBar, this.progressFill, icon]);

        this.progressContainer.setPosition(this.cameras.main.width / 2, 60);

        this.progressContainer.setVisible(false);

        this.footballGuy = this.add.spine(
            170,
            height - 30,
            "footballGuy",
            "footballGuy"
        );

        this.anims.create({
            frameRate: 30,
            frames: [
                { key: "frame1" },
                { key: "frame2" },
                { key: "frame3" },
                { key: "frame4" },
            ],
            key: "clickEffect",
            repeat: 1,
        });

        this.footballGuy.setScale(0.5, 0.5);

        this.footballGuy.animationState.setAnimation(0, "idle", true);
        this.energy = 400;

        this.heightTitleText = this.add.text(0, 0, "", {
            align: "center",
            fixedWidth: 200,
            fontFamily: "Kanit",
            fontSize: "20px",
        });

        this.checkResultsText = this.add.text(
            this.cameras.main.width / 2 - 124,
            this.cameras.main.height / 2 + 40,
            "",
            {
                align: "center",
                fontFamily: "Comica BD",
                fontSize: "20px",
            }
        );

        this.fpsVersionText = this.add.text(
            120,
            700,
            `version: ${this.config.version}`,
            {
                align: "center",
                fixedWidth: 200,
                fontFamily: "Arial",
                fontSize: "12px",
            }
        );

        this.heightCounterText = this.add.text(0, 20, "", {
            align: "center",
            fixedWidth: 200,
            fontFamily: "Countdown",
            fontSize: "80px",
        });

        counterTextContainer.add([
            this.heightTitleText,
            this.heightCounterText,
        ]);

        counterTextContainer.setY(155);

        this.gameOverText = this.add.text(-60, 0, "", {
            align: "center",
            color: "white",
            fontFamily: "Comica BD",
            fontSize: "56px",
        });

        this.startText = this.add.text(
            this.cameras.main.width / 2 - 110,
            this.cameras.main.height / 2 - 200,
            "Tap to start",
            {
                align: "center",
                color: "white",
                fontFamily: "Comica BD",
                fontSize: "32px",
            }
        );

        this.checkButton = this.add.sprite(100, 130, "button");

        this.checkButton.setInteractive();

        this.checkButton.on("pointerdown", () => {
            const a = document.createElement("a");
            a.href = `/results?points=${this.height}`;
            a.click();
        });

        gameOverTextContainer.add([this.gameOverText, this.checkButton]);

        gameOverTextContainer.setY(260);

        this.input.on("pointerdown", this.jump, this);

        this.checkButton.setVisible(false);

        setInterval(() => {
            if (this.isGameOver || !this.isGameStarted) {
                return;
            }

            this.energy -= this.decreaseEnergyCoeff;

            this.progressFill?.setCrop(
                16,
                0,
                this.energy,
                this.progressFill.height
            );
        }, 300);

        this.ball.setDepth(100);
        EventBus.emit("current-scene-ready", this);
    }

    moveBall(currentEnergy: number) {
        const { x, y } = this.calculateCoordinates(currentEnergy);

        this.tweens.add({
            duration: 1000,
            ease: "Power1",
            onUpdate: () => {
                this.ball?.setPosition(this.ball.x, this.ball.y);
            },
            targets: this.ball,
            x,
            y,
        });
    }

    calculateCoordinates(
        currentEnergy: number,
        maxEnergy: number = 400,
        blockWidth: number = 200,
        blockHeight: number = 200
    ) {
        // Проверка на корректность значений энергии
        // if (currentEnergy < 0 || currentEnergy > maxEnergy) {
        //     throw new Error("Current energy must be between 0 and max energy.");
        // }

        // Вычисление процентного соотношения текущей энергии к максимальной
        const energyPercentage = currentEnergy / maxEnergy;

        // Вычисление координат по диагонали
        const x = blockWidth * energyPercentage;
        const y = blockHeight * (1 - energyPercentage);

        return { x, y };
    }

    spawnBaloons() {
        const baloon = this.add.spine(440, -60, "baloonJson", "baloonAtlas");

        baloon.setInteractive();

        baloon.animationState.setAnimation(0, "animation", true);

        baloon.setScale(0.5, 0.5);
        baloon.setDepth(-2);

        this.baloons.push(baloon);
    }

    spawnSaturn() {
        const saturn = this.add.spine(440, -60, "saturnJson", "saturnAtlas");

        saturn.setInteractive();

        saturn.animationState.setAnimation(0, "animation", true);

        saturn.setScale(0.5, 0.5);
        saturn.setDepth(-2);

        this.baloons.push(saturn);
    }

    spawnStars() {
        const star = this.add.sprite(440, -320, "stars");

        star.setDepth(-4);
        this.stars.push(star);
    }

    spawnRockets() {
        if (this.isGameOver) return;

        const obj = this.add.spine(0, 550, "rocketJson", "rocketAtlas");
        obj.flipX = true;

        obj.setInteractive();

        obj.animationState.setAnimation(0, "animation", true);

        obj.setScale(0.5, 0.5);
        obj.setDepth(-1);

        this.rockets.push(obj);
    }

    spawnNlo() {
        const randomIndex = this.getRandomInt(1, 3);
        let obj: any;

        if (Math.round(randomIndex) === 1) {
            obj = this.add.spine(
                460,
                this.getRandomInt(200, 730),
                "nloJson",
                "nloAtlas"
            );
            obj.flipX = true;
        } else {
            obj = this.add.spine(
                0,
                this.getRandomInt(200, 730),
                "nloJson",
                "nloAtlas"
            );
        }

        obj.setInteractive();

        obj.animationState.setAnimation(0, "animation", true);

        obj.setScale(0.5, 0.5);
        obj.setDepth(-1);

        this.nlos.push(obj);
    }

    centerBackground() {
        this.background!.setPosition(
            this.cameras.main.width / 2,
            this.cameras.main.height / 2
        );
        this.background!.setOrigin(0.25, 0.4);
    }

    resize(gameSize: any, _: any, __: any, ___: any) {
        const { width } = gameSize;
        const { height } = gameSize;

        this.cameras.resize(width, height);
        this.centerBackground();
    }

    update(_: any, delta: number) {
        if (!this.isGameOver && this.isGameStarted) {
            if (this.energy <= 0) {
                this.isGameOver = true;
                this.ball?.animationState.setAnimation(1, "boom", false);

                return;
            }

            if (this.height > this.config.saturn.min && !this.isSaturnSpawned) {
                if (this.isGameOver || !this.isGameStarted) return;

                this.isSaturnSpawned = true;
                this.spawnSaturn();
            }

            this.fpsVersionText?.setText(
                `FPS ${Math.floor(this.game.loop.actualFps)} version: ${
                    this.config.version
                }`
            );

            this.time.delayedCall(
                1000,
                () => {
                    this.moveBall.call(this, this.energy);
                },
                [],
                this
            );

            this.baloons.forEach((baloon) => {
                baloon.y += this.config.baloons.speedY * delta;
                baloon.x -= this.config.baloons.speedX * delta;

                if (baloon.y > 1400) {
                    baloon.destroy();
                }
            });

            this.stars.forEach((star) => {
                star.y += this.config.baloons.speedY * delta;
                star.x -= this.config.baloons.speedX * delta;

                if (star.y > 800) {
                    star.destroy();
                }
            });

            this.clouds.forEach((cloud) => {
                cloud.y += this.config.baloons.speedY * delta;
                cloud.x -= this.config.baloons.speedX * delta;

                if (cloud.y > 800) {
                    cloud.destroy();
                }
            });

            this.nlos.forEach((obj) => {
                if (obj.flipX) obj.x -= this.config.nlos.speedX * delta;
                else obj.x += this.config.nlos.speedX * delta;

                if (obj.x < 0 || obj.x > 800) {
                    obj.destroy();
                }
            });

            this.planes.forEach((obj) => {
                obj.x += this.config.planes.speedX;

                if (obj.flipX) obj.y += this.config.planes.speedY * delta;
                else obj.y -= this.config.planes.speedY * delta;

                if (obj.x < 0) {
                    obj.destroy();
                }
            });

            this.rockets.forEach((obj) => {
                obj.x += this.config.rockets.speedX * delta;
                obj.y -= this.config.rockets.speedY * delta;

                if (obj.x < 0) {
                    obj.destroy();
                }
            });

            this.background!.y += this.backgroundSpeedY;
            this.background!.x -= this.backgroundSpeedX;

            this.heightCounterText?.setText(Math.round(this.height).toString());
        } else {
            this.nlos.forEach((obj) => {
                obj.destroy();
            });

            this.planes.forEach((obj) => {
                obj.destroy();
            });

            if (this.isGameOver) {
                this.gameOverText?.setText("Game over");
                this.checkResultsText?.setText(
                    "Hold on, we're tallying \r the results"
                );

                if (this.isFinishGame) {
                    this.isFinishGame = false;

                    axiosInstance
                        .post(`games/${this.gameId}/`, {
                            ...encrypt(
                                this.height.toString(),
                                import.meta.env.VITE_APP_SECRET_KEY
                            ),
                        })
                        .finally(() => {
                            this.checkResultsText?.destroy();
                            this.checkButton?.setVisible(true);
                        });
                }
            }
        }
    }

    getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    spawnClouds() {
        if (this.isGameOver) return;

        const randomIndex = this.getRandomInt(1, 18);

        const cloud = this.add.sprite(
            this.getRandomInt(10, 430),
            -20,
            `cloud${randomIndex}`
        );

        cloud.setDepth(-3);
        this.clouds.push(cloud);
    }

    spawnPlanes() {
        if (this.isGameOver) return;

        const randomIndex = this.getRandomInt(1, 2);

        let obj: SpineGameObject | null = null;

        if (randomIndex === 1) {
            obj = this.add.spine(0, 450, "planeJson", "planeAtlas");
        } else if (randomIndex === 2) {
            obj = this.add.spine(0, 250, "plane2Json", "plane2Atlas");
            obj.flipX = true;
        }

        obj?.setInteractive();

        obj?.animationState.setAnimation(0, "animation", true);

        obj?.setScale(0.5, 0.5);
        obj?.setDepth(-1);

        this.planes.push(obj);
    }

    jump(pointer: any) {
        if (!this.isGameStarted && !this.isAnimStarted) {
            this.isAnimStarted = true;

            this.footballGuy?.animationState.setAnimation(
                0,
                "action_speed",
                false
            );
            this.startText?.setVisible(false);

            setTimeout(() => {
                this.isGameStarted = true;
                this.ball?.setVisible(true);

                this.progressContainer.setVisible(true);
                this.heightTitleText?.setText("Score: ");
                this.heightCounterText?.setText("0");

                this.ball?.animationState.setAnimation(0, "fly", true);

                setInterval(() => {
                    this.footballGuy!.y += 18;
                    this.footballGuy!.x -= 18;
                }, 10);
            }, 3500);
        }

        if (this.isGameStarted && !this.isGameOver) {
            const value = this.height < 500 ? 1 : this.getRandomInt(1, 8);

            this.height += value;

            const sprite = this.add.sprite(pointer.x, pointer.y, "frame1");
            sprite.play("clickEffect");

            const text = this.add.text(pointer.x, pointer.y - 20, `+${value}`, {
                fontFamily: "Kanit",
                fontSize: "26px",
            });

            this.tweens.add({
                alpha: { from: 1, to: 0 },
                duration: 1000,
                ease: "Power1",
                onComplete: () => {
                    text.destroy();
                },
                targets: text,
                y: text.y - 50,
            });

            setTimeout(() => {
                sprite.destroy();
            }, 300);
        }

        if (this.isGameOver) {
            return;
        }

        if (this.height > 400 && this.height < 800) {
            this.energy += this.decreaseEnergyCoeff - 3;
        } else if (this.height > 800 && this.height < 1200) {
            this.energy += this.decreaseEnergyCoeff - 5;
        } else if (this.height > 1200 && this.height < 1600) {
            this.energy += this.decreaseEnergyCoeff - 6;
        } else if (this.height < 400) {
            this.energy += this.decreaseEnergyCoeff;
        }

        if (this.energy > 400) {
            this.energy = 400;
        }
    }
}
