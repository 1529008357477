import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import IconBlock from "@atoms/IconBlock";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import { FrensSVG } from "@svg/FrensSVG";
import { ShelfSVG } from "@svg/ShelfSVG";
import { TicketSVG } from "@svg/TicketSVG";

import style from "../PlayScreen.module.scss";

const PassEarner = () => {
    const navigate = useNavigate();

    return (
        <div className={style.getPassesContainer}>
            <WindowBlock
                className={style.passButton}
                onClick={() => navigate(routes.frens)}
            >
                <IconBlock className={style.iconPass} mode="gradient">
                    <FrensSVG />
                </IconBlock>
                <Typography className={style.btnContainer} variant="text2Bold">
                    Invite a fren for
                    <TicketSVG
                        style={{ height: 14, marginLeft: 6, width: 14 }}
                    />
                </Typography>
            </WindowBlock>
            <WindowBlock
                className={style.passButton}
                onClick={() => navigate(routes.shelf)}
            >
                <IconBlock className={style.iconPass} mode="gradient">
                    <ShelfSVG />
                </IconBlock>
                <Typography className={style.btnContainer} variant="text2Bold">
                    Use a shelf for
                    <TicketSVG
                        style={{ height: 14, marginLeft: 6, width: 14 }}
                    />
                </Typography>
            </WindowBlock>
        </div>
    );
};

export default PassEarner;
