import { createSlice } from "@reduxjs/toolkit";

import { getLootBoxData, getPerks } from "./lootboxes.thunk";

export interface ILootBoxesState {
    count: number;
    price: number;
}

export interface ILootBoxPerks {
    title: string;
    amounts: number[];
}

export interface ILootBoxState {
    lootBoxId: number | null;
    lootBoxes: ILootBoxesState[] | null;
    perks: ILootBoxPerks[] | null;
    isLoading: boolean;
    isLoadingPerks: boolean;
}

const initialState: ILootBoxState = {
    lootBoxId: null,
    lootBoxes: null,
    perks: null,
    isLoading: false,
    isLoadingPerks: false,
};

export const lootboxesSlice = createSlice({
    initialState,
    name: "lootboxes",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLootBoxData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLootBoxData.fulfilled, (state, action) => {
                const { payload } = action;
                state.lootBoxId = Number(payload.id);
                state.lootBoxes = payload.prices.map((price, index) => ({
                    count: index + 1,
                    price,
                }));
                state.isLoading = false;
            })
            .addCase(getLootBoxData.rejected, (state, action) => {
                state.isLoading = false;
                const { error } = action;
                // eslint-disable-next-line no-console
                console.error(error.message);
            })

            .addCase(getPerks.pending, (state) => {
                state.isLoadingPerks = true;
            })
            .addCase(getPerks.fulfilled, (state, action) => {
                state.isLoadingPerks = false;
                const { payload } = action;
                if (!Array.isArray(payload)) return;
                const perks = payload.reduce((a, b) => {
                    a[b.type] ??= { title: b.title, amounts: [] };
                    if (b.amount > 0) {
                        a[b.type].amounts.push(b.amount);
                    }
                    return a;
                }, {});
                state.perks = Object.values(perks);
            })
            .addCase(getPerks.rejected, (state, action) => {
                state.isLoadingPerks = false;
                const { error } = action;
                // eslint-disable-next-line no-console
                console.error(error.message);
            });
    },
});

// export const actions = referralSlice.actions;

export default lootboxesSlice.reducer;
