import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import { ILootBoxData, IOpenLootBoxData } from "@schemas/lootboxes";

const url = API_URLS.getLootBoxData;

export class LootBoxAPI {
    static async getLootBoxData(): AxiosPromise<ILootBoxData> {
        return Request.get(`${url}`).then((response) => {
            if (!response.data.success) throw new Error(response.data.message);
            return response.data;
        });
    }

    static async openLootBox({
        lootBoxId,
        count,
    }: {
        lootBoxId: number;
        count?: number;
    }): AxiosPromise<IOpenLootBoxData> {
        return Request.post(`${url}${lootBoxId}/`, { count }).then(
            (response) => {
                if (!response.data.success)
                    throw new Error(response.data.message);
                return response.data;
            }
        );
    }

    static async getLootBoxPerks({
        lootBoxId,
    }: {
        lootBoxId: number;
    }): AxiosPromise<ILootBoxData> {
        return Request.get(`${url}${lootBoxId}/items/`).then((response) => {
            if (!response.data.success) throw new Error(response.data.message);
            return response.data;
        });
    }
}
